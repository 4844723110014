<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-06-02 09:39:27
 * @LastEditTime: 2021-06-02 22:48:09
-->
<template>
<div v-html="content"></div>
</template>

<script>
export default {
  data() {
    return {
        content:`<div style="text-align: center;"><h2>智赋云平台注册协议与隐私政策</h2></div>
	
    <div className='privacy'>
        <p><h4>智赋云平台注册协议</h4></p>
        <p>欢迎您(即本协议项下的＂用户＂)与智赋云（成都）企业服务有限公司(以下简称智赋云)签署《智赋云平台注册协议》(以下简称&ldquo;本协议＂)</p>
        <p>【敬请阅读】本协议各条款前索引关键词仅为帮助您理解该条款表达主旨之用，不影响或限制本协议条款的含义或解释。您在注册流程中点击同意本协议之前，请认真阅读本协议，特别是采用粗体字标注的免除或限制责任的条款、法律适用和争议解決条款，确保您充分理解本协议中各项条款。</p>
        <p>【特别提示】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与智赋云达成一致，成为智赋云平台的&ldquo;用户＂。读本协议的过程中，如果您不同意本协议任何条款的约定，您应立即停止注册。</p>
        <p>一、定义</p>
        <p>智赋云平台经营者:指智赋云（成都）企业服务有限公司。智赋云平台/件:指智赋云开发的智赋云助手微信小程序、分时领英小程序及智赋云APP。</p>
        <p>智赋云服务: 智赋云通过智赋云平台/軟件、互联网及线下向您提供的各项服务。</p>
        <p>智赋云平台规则: 包括在智赋云平台内已经发布及后续发布的全部规则、解读、公告等内容。</p>
        <p>用户: 指注册、登录、使用智赋云平台/软件的个人，即本协议项下的您。</p>
        <p>身份要素: 指智赋云用于识别您身份的信息要素，包括但不限于您的账号名称及密码、姓名、身份证号码或其他有效证件号码、手机号码、电子邮箱、微信账号或其他第三方账号等信息。</p>
        <p>二、协议范围</p>
        <p>1、签约主体</p>
        <p>【平等主体】本协议由您与智赋云共同缔结，本协议对您与智赋云均具有合同效力。【主体信息】智赋云是经营智赋云平台的法律主体。本协议项下，智赋云平台经营者可能根据智赋云平台的业务调整而发生変更，变更后的智赋云平台经营者与您共同履行本协议并向您提供服务，智赋云平台经营者的变更不会影响您在本协议项下的权益。智赋云平台经营者还有可能因为提供新的智赋云服务而新增，如您使用新增的智赋云服务的，视为您同意新增的智赋云平台经营者与您共同履行本协议。</p>
        <p>2、补充协议</p>
        <p>由于互联网高速发展，您与智赋云签署的本协议列明的条款并不能完整罗列并覆盖您与智赋云所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，智赋云平台隐私政策、平台规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用智赋云平台服务，视为您同意前述补充协议。</p>
        <p>三、服务内容</p>
        <p>智赋云为您提供平台使用服务:智赋云助手平台根据企业客户的业务需求，为您提供身份认证、线上签约及查看收入流水等服务。</p>
        <p>四、软件的安装、卸载与更新</p>
        <p>1、软件的获取</p>
        <p>您可以从智赋云的官方网站或得到智赋云授权的第三方获取智赋云软件。如您从未经智赋云授权的第三方获取智赋云软件，智赋云无法保证该软件能够正常使用，并对因此给您造成的损失不承担责任。</p>
        <p>2、软件的安装</p>
        <p>智赋云可能为不同的终端设备开发了不同的软件类型或版本，您应当根据实际情况选择下载合适的版本进行安装。</p>
        <p>3、软件的卸载</p>
        <p>如您不再需要使用智赋云软件，可以自行卸载或删除。</p>
        <p>4、软件的更新</p>
        <p>为了改善用户体验、完善服务内容，智赋云将不断努力开发新的服务，并不时进行软件更新(更新形式包括但不限于软件替换、修改、功能强化、版本升级)。为了保证软件及服务的安全性和功能的一致性，智赋云有权不经向您通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。软件新版本发布后，智赋云不保证旧版本软件继续可用，请您随时核对并下载最新版本。</p>
        <p>五、账号注册与使用</p>
        <p>1、用户资格</p>
        <p>您确认，在您注册智赋云平台/软件账号时，您系具有完全民事权利能力和民事行为能力或已满十六周岁未满十八周岁经法定监护人同意的民事主体。若您不具备前述与您行为相适应的民事主体资格，则您及您的监护人应依照法律规定承担因此导致的一切后果。</p>
        <p>2、账号注册与使用</p>
        <p>【账号注册】您在成为智赋云平台的合法用户前，需要注册智赋云账号。智赋云账号可通过手机号码、身份证号码或其他有效证件号码、电子邮箱、微信账号或其他第三方账号授权等身份要素进行注册。智赋云有权根据用户需求或产品需要对账号注册方式进行变更，具体以智赋云平台注册程序所显示的信息为准。</p>
        <p>【账号使用权】智赋云账号的所有权归智赋云所有，您完成申请、注册程序后，即成为该账号的初始注册人，并获得账号的使用权。您须对注册智赋云账号所用信息的真实性、合法性、有效性承担责任，不得赠与、借用、租用、转让、售卖智赋云账号或以其他方式许可非初始注册人使用智赋云账号。非初始注册人不得通过受赠、继承、承租、受让或其他任何方式使用智赋云账号。如发现存在前述任一行为，智赋云有权立即停止提供服务，收回智赋云账号并由该账号的初始注册人承担由此而产生的全部责任。</p>
        <p>【账号与密码的保管】您应采取合理措施妥善保管账号和密码的安全，您同意在任何情况下不向他人透露账号及密码信息。您需对注册账号和密码下的一切行为负全部责任，由该等行为所导致的任何损失或损害由您承担。如您的账号和密码遭到未授权的使用或发生其他任何安全问题，您应立即通知智赋云。</p>
        <p>六、用户行为规范</p>
        <p>1、信息发布规范</p>
        <p>您不得发布、传播、储存违反国家法律法规的以下内容</p>
        <p>（1）违反宪法所确定的基本原则的;</p>
        <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（3）损害国家荣誉和利益的;</p>
        <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的</p>
        <p>（6）散布谣言，抗乱社会秩序，破坏社会稳定的;</p>
        <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;</p>
        <p>（8）侮辱或者诽谤他人，侵害他人合法权益的;</p>
        <p>（9）虛构事实、隐瞒真相以误导、欺骗他人的;</p>
        <p>（10）含有法律、法规禁止的其他內容的信息。</p>
        <p>2、账号使用规范</p>
        <p>（1）提交、发布虚假信息，或盗用他人账号或资料，冒充、利用他人名义的;</p>
        <p>（2）强制、诱导其他用户关注、点击链接页面或分享信息的;</p>
        <p>（3）虚构事实、隐瞒真相以误导、欺骗他人的</p>
        <p>（4）利用技术手段批量建立虚假账号的;</p>
        <p>（5）侵犯、妨害、威胁他人权利或安全的;</p>
        <p>（6）利用智赋云账号或本服务从事任何违法犯罪活动的;</p>
        <p>（7）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的;</p>
        <p>（8）其他违反法律法规规定、侵犯其他用户合法权益、干扰智赋云平台正常运营或智赋云未明示授权的行为。</p>
        <p>3、软件使用规范</p>
        <p>除法律允许或智赋云书面许可，您使用智赋云软件的过程中不得从事下列行为</p>
        <p>（1）删除软件及其副本上关于著作权的信息;</p>
        <p>（2）对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码;</p>
        <p>（3）对智赋云拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发布、出版、建立镜像站点等；</p>
        <p>（4）对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经智赋云授权的第三方工具/服务接入本软件和相关系统;</p>
        <p>（5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的;</p>
        <p>（6）通过非智赋云开发、授权的第三方软件、插件、外挂、系统，登录或使用智赋云软件及服务，或制作、发布传播上述工具；</p>
        <p>（7）自行或者授权他人、第三方软件对软件及其组件、模块、数据进行干扰;</p>
        <p>（8）其他未经智赋云明示授权的行为</p>
        <p>4、智赋云有权依其合理判断，对违反有关法律法规或本协议约定的行为，依法停止传输违反法律规定或本协议约定的任何内容，并有权依其自行判断对违反本条款的用户采取适当的法律措施，包括但不限于从智赋云平台中删除具有违法性、侵权性、不当性的内容，停止向违反者提供全部或部分智赋云服务及封禁账号的全部或部分功能等。</p>
        <p>5、智赋云提供的服务中可能包括广告，您同意在使用过程中显示智赋云和第三方供应商提供的广告。除法律法规明确规定外，您应自行对依该广告信息进行的交易负责，对您因依该广告信息进行的交易或前述广告商提供的內容而遭受的损失或损害，智赋云不承担任何责任。</p>
        <p>七、个人信息保护</p>
        <p>1、保护用户个人信息是智赋云的一项基本原则，智赋云将会依法采取合理的措施保护用户的个人信息。智赋云对用户相关信息采用专业加密存储与传输方式，并运用各种安全技术和程序建立完善的管理制度来保护用户隐私信息，以免遭受未经授权的访问、使用或披露。</p>
        <p>2、您在注册账号或使用智赋云的服务过程中，需要提供些必要的信息，您同意向智赋云提供该等信息，如:为向您提供账号注册服务或进行用户身份识别，需要您填写手机号码、邮箱地址或授权智赋云获取您的微信账号或其他第三方账号等。</p>
        <p>3、除法律法规规定外，未经您的同意，智赋云将不会擅自披露、改变或以其他方式使用您的个人信息。若您认为智赋云存在违反前述范围使用该等个人信息的，应立即与智赋云进行联系，智赋云将及时核实处理。</p>
        <p>4、智赋云平台收集、存储、使用、披露和保护个人信息的详细政策请见《智赋云隐私政策》，请您完整阅读、充分理解该隐私政策，以更好地保护您的隐私权。</p>
        <p>八、知识产权</p>
        <p>1、智赋云是智赋云软件的知识产权权利人。智赋云软件的切著作权、商标权、专利权、商业秘密等知识产权，以及与智赋云软件相关的所有信息内容(包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等)均受中华人民共和国法律法规和相应的国际条约保护，智赋云享有前述知识产权，但相关权利人依照法律规定应享有的权利除外。</p>
        <p>2、未经智赋云或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、使用、转让上述知识产权。</p>
        <p>3、您使用智赋云软件及服务过程中上传、发布的全部内容，均不会因为上传、发布行为发生知识产权、肖像权等权利的转移。与此同时，您理解并同意智赋云为实现产品或服务目的，对您提供或发布的信息在履行法定授权程序后且在法律允许的范围內进行使用，包括但不限于予以存储、进行第三方信息核验、为提供更好的服务共享给第三方等。</p>
        <p>九、法律责任</p>
        <p>1、如果智赋云发现或收到他人举报或投诉您违反法律法规规定或本协议约定的，智赋云有权不经通知随时对相关内容(包括但不限于您的个人资料、服务记录)进行审查、删除，并视情节轻重对违规账号采取包括但不限于警告、账号封禁、功能封禁的措施，且通知您处理结果</p>
        <p>2、被实施功能封禁的账号会在封禁期届满后自动恢复被封禁功能。如您的账号被封禁，您可向智赋云平台提交申诉，智赋云将对申诉进行审查，并自行合理判断是否变更处理措施</p>
        <p>3、您理解并同意，智赋云有权依合理判断对违法违规的任何用户采取适当的法律措施，并依据法律法规保存相关信息及向有关部门报告、配合有关主管部门调查、向公安机关报案等，用户应承担由此而产生的一切法律责任。</p>
        <p>4、您理解并同意，如因您的个人行为导致智赋云或其关联方遭受损失或任何第三方的索赔或起诉，您应向智赋云及其关联方承担损害赔偿责任，赔偿范围包括全部损失、合理的律师费、公证费、诉讼费、差旅费等。</p>
        <p>十、不可抗力及其他免责事由</p>
        <p>1、您理解并确认，在使用智赋云服务的过程中，可能会遇到不可抗力等风险因素使服务发生中断。不可抗力是指不能预见、不能避免并不能克服的客观事件，包括但不限于洪水、地震、瘟疫和风暴等自然灾害以及战争、动乱、政府行为等社会事件。发生不可抗力事件时，智赋云将努力在第时间与相关单位配合，及时恢复提供服务，但是由此给您或第三方造成的损失，智赋云在法律允许的范围內免责</p>
        <p>2、智赋云服务同多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人盗用用户资料，给您造成骚扰;您下载安装的其它软件或访问的其他网站中含有&ldquo;木马＂等病毒，威胁到您的计算机信息和数据的安全迷而影响智赋云服务的正常使用等。您应加强信息安全及个人资料的保护意识，注意加强账号及密码保护，以免遭致损失，继而影响智赋云服务的正常使用等。非因智赋云原因导致您或第三方的任何损失，智赋云不承担任何责任。</p>
        <p>3、您理解并确认，智赋云服务存在因政府行为、不可抗力、计算机病毒或黑客攻击、系統不稳定、用户所在位置、用户关机以及其他任何技术、网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的您或第方的任何损失，智赋云不承担任何责任。</p>
        <p>4、您理解并确认，在使用智赋云服务过程中可能存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，您应注意甄别该类信息，因此导致您或第三方的任何损失，智赋云不承担任何任</p>
        <p>5、您理解并确认，智赋云需要定期或不定期地对智赋云平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，智赋云无需为此承担任何责任，但智赋云应尽可能事先进行通告。</p>
        <p>6、您理解并确认，智赋云依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成智赋云的义务或承诺，智赋云不能保证及时发现违法违规或违约行为或进行相应处理。</p>
        <p>十ー、协议的变更</p>
        <p>智赋云可根据国家法律法规变化及业务发展需要，不时修改本协议、补充协议，変更后的协议、补充协议(下称&ldquo;变更事项)将通过法定程序并以本协议第十三条约定的方式通知您</p>
        <p>如您不同意变更事项，您应当于变更事项确定的生效之日起停止使用智赋云服务，变更事项对您不产生效力;如您在变更事项生效后仍继续使用智赋云服务，则视为您同意已生效的变更事项。</p>
        <p>十二、协议的终止</p>
        <p>1、您有权通过以下任一方式终止本协议:</p>
        <p>（1）您通过自主注销或智赋云人工服务注销您的账号的;</p>
        <p>（2）变更事项生效前您停止使用智赋云服务并通过有效通知明示不愿接受变更事项的;</p>
        <p>（3）您通过有效通知明示不愿继续使用智赋云服务的。您在智赋云平台的账号注销后，您将无法继续使用智赋云服务。账号一旦注销成功，账号记录、账号功能等将无法恢复或提供。如您在注销账号前存在违约、侵权等不当行为或未履行义务的，您仍应承担相应责任</p>
        <p>2、出现以下任一情况时，智赋云有权以本协议第十三条的所列的方式通知您终止本协议；</p>
        <p>（1）智赋云通过您提供的个人信息判断您不满足本协议约定的用户资格的;</p>
        <p>（2）您违反本协议约定，智赋云依据违约条款终止本协议的</p>
        <p>（3）您违反本协议第六条约定的用户行为规范的;</p>
        <p>（4）您在智赋云平台有欺诈、侵犯他人合法权益或其他严重违法违约行为的;</p>
        <p>（5）智赋云按照法律规定或有权机关的要求终止向您提供服务的。</p>
        <p>十三、通知</p>
        <p>1、有效联系方式</p>
        <p>（1）您的联系方式</p>
        <p>您在注册成为智赋云平台用户并接受智赋云平台服务时，您应该向智赋云提供真实有效的联系方式(包括您的电子邮箱地址、联系电话、微信等)，对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态</p>
        <p>您在注册智赋云平台用户时生成的用于登陆智赋云平台接收系统消息的账号，也作为您的有效联系方式。</p>
        <p>智赋云将向您的上述联系方式的其中之ー或其中若干送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注；</p>
        <p>(2)智赋云的联系方式</p>
        <p>智赋云的联系方式包括如下客服热线、客服电子邮箱及智赋云平台/件上公布的其他联系方式：</p>
        <p>智赋云客服热线:</p>
        <p>智赋云客服电子邮箱：</p>
        <p>2、通知的送达</p>
        <p>智赋云通过上述联系方式向您发出通知，其中以电子方式发出的书面通知，包括但不限于在智赋云平台发布公告、向您提供的联系电话发送手机短信、向您提供的电子邮箱地址发送电子邮件及向您的账号发送平台通知，在发送成功后即视为送达。</p>
        <p>您可通过您预留的电子邮箱地址、联系电话或通过智赋云平台的在线客服向云赚户发田通知，您通过前述联系方式发出的通知视为您本人的意思表示，在发送成功后视为送达;您以纸质载体发出的书面通知，按照智赋云联系地址交邮后的第五个自然日即视为送达</p>
        <p>十四、法律适用、管辖与其他</p>
        <p>1、本协议的订立、生效、解释、修订、补充、终止、执行与争议解決，均适用中华人民共和国境内法律</p>
        <p>2、若您因使用智赋云平台服务与智赋云之间发生任何纠纷或争议，应友好协商解決，协商不成的，任何一方均有权向智赋云所地的人民法院提起诉讼。</p>
        <p>3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
    </div>
	<br />
	<div className='UserAgreement'>
	    <p ><h4>智赋云隐私政策</h4></p>
	    <p>引：</p>
	    <p>智赋云非常重视您的个人信息保护，会依法保护您的个人信息，同时制定严格的政策来管理所有数据。使用智赋云提供的服务前，您需要同意本政策中关于智赋云如何收集、使用、分享和保护您的相关信息的规定。如果您不同意本隐私政策中关于基本业务功能的相关内容，可能将影响为您提供相应产品和/或服务的效果。本政策将帮助您了解以下内容</p>
	    <p>一、智赋云如何收集和使用您的个人信息</p>
	    <p>二、智赋云对 okies和同类型技术的使用</p>
	    <p>三．智赋云如何共享、转让、公开披露您的信息</p>
	    <p>四、智赋云如何存储您的个人信息</p>
	    <p>五、智赋云如何保护您的个人信息</p>
	    <p>六、您的权利</p>
	    <p>七、未成年人的信息保护</p>
	    <p>八、智赋云向您发送的信息</p>
	    <p>九、智赋云服务中的第三方服务</p>
	    <p>十、适用范围</p>
	    <p>十ー、本隐私政策的修改</p>
	    <p>十二、如何联系我们</p>
	    <p>除有特殊约定外，本隐私政策中使用的名词与《智赋云平台注册协议》中定义的名词具有相同的含义。</p>
	    <p>本隐私政策与您使用智赋云服务关系紧密，请您仔细阅读并理解本隐私政策的全部内容。智赋云将对本隐私政策中与您的权益存在重大关系的条款和个人敏感信息，采用粗体字进行标注以提示您注意。</p>
	    <p>为了遵守国家法律法规及监管规定，也为了向您提供服务及提升服务质量，智赋云需要收集、存储、使用及对外提供您的信息。您同意智赋云按照本政策约定处理您的信息，以便您享受优质、便捷、个性化的服务，同时更好地保护您的账号安全。</p>
	    <ul>
	        <li>智赋云如何收集和使用您的个人信息</li>
	    </ul>
	    <p>为了保证智赋云用户注册、账户和服务安全、签约等客户服务各项基本业务功能及各项扩展业务功能的正常运行，智赋云会收集和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为智赋云的用户或无法使用和享受智赋云提供的某些产品和服务，或者无法达到相关产品和/或服务拟达到的效果。</p>
	    <p>1、基本业务功能</p>
	    <p>(1)智赋云用户注册。为创建账号，智赋云收集并使用您的手机号码、微信账号或其他第三方账号。</p>
	    <p>(2)账户和服务安全。为运营商认证、安全防范、诈骗检测、存档和备份等活动，确保智赋云向您提供的产品和服务的安全性，您使用智赋云的产品或服务时系统会通过Cookies、 Web Beacon或其他方式自动采集技术信息，包括您的移动设备的配置信息、IP地址和移动设备所用的版本和设备识别码。</p>
	    <p>(3)签约等客户服务。为了进行身份验证、银行卡鉴权等活动，执行产品/服务验证、升级服务，帮助智赋云设计新服务、改善智赋云现有服务，智赋云将通过您主动提供或者智赋云应用中嵌入的授权合作伙伴软件开发工具包SDK或者AP接口收集并使用您的姓名、身份证号码或其他有效证件号码、证件照片、银行卡卡号、银行预留手机号码。为了确保收款方确实为您本人，避免盗用身份信息与银行卡信息的情况，保障智赋云服务的安全性，智赋云还会收集并使用您的人脸照片</p>
	    <p>(4)客户服务。为保障您的账号安全，智赋云在向您提供客户服务时可能会使用您的账号信息来核实您的身份;在您需要提供客服咨询时，智赋云会查询您的智赋云服务使用记录帮助您解決产品或服务问题。您在与智赋云客服或服务人员进行沟通、咨询时，智赋云会对沟通进行记录。</p>
	    <p>2、拓展业务功能</p>
	    <p>智赋云平台现尚未提供个性化服务及拓展业务功能，如此后提供，智赋云将会在征求您同意的前提下开启前述功能。</p>
	    <p>3、依法豁免征得同意收集和使用的个人信息</p>
	    <p>您理解并确认，在下列情形中，根据法律法规及相关国家标准，智赋云收集和使用您的个人信息无需征得您的授权同意：</p>
	    <p>(1)与国家安全、国防安全直接相关的;</p>
	    <p>(2)与公共安全、公共卫生、重大公共利益直接相关的</p>
	    <p>(3)与犯罪侦査、起诉、审判和判決执行等直接相关的;</p>
	    <p>(4)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;</p>
	    <p>(5)所收集的您的个人信息是您自行向社会公众公开的;</p>
	    <p>(6)从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道</p>
	    <p>(7)根据您的要求签订或履行协议所必需的</p>
	    <p>(8)用于维护软件及相关服务的安全稳定运行所必需的、例如发现、处置软件及相关服务的故障</p>
	    <p>(9)为合法的新闻报道所必需的;</p>
	    <p>(10)学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
	    <p>(11)法律法规规定的其他情形</p>
	    <p>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息;当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
	    <p>二、智赋云对 Cookies和同类型技术的使用</p>
	    <p>Cookies:和同类型技术是互联网中的通用常用技术。当您使用智赋云相关服务时，智赋云可能会使用相关技术向您的设备发送一个或多个 Cookies或匿名标识符，以收集和存储您访问、使用智赋云服务时的信息。智赋云使用 Cookies和同类技术主要为了实现以下功能或服务</p>
	    <p>1、保障产品与服务的安全、高效运转</p>
	    <p>智赋云可能会设置认证与保障安全性的 Cookies或匿名标识</p>
	    <p>符，使智赋云确认您是否安全登录服务，或者是否遭受盗用、欺诈等不法行为。这些技术还会帮助智赋云改进服务效率，提升登录和响应速度。</p>
	    <p>2、帮助您获得更轻松的访问体验</p>
	    <p>使用 okies和同类型技术可以帮助您省去重复填写个人信息、判断您的登录状态以及账号或数据安全等。</p>
	    <p>3、为您推荐、展示、推送您可能感兴趣的内容</p>
	    <p>智赋云可能会利用 okies和同类技术了解您的偏好和使用习惯，进行咨询或数据分析，以改善产品服务及用户体验。</p>
	    <ul>
	        <li>智赋云如何共享、转让及公开披露您的信息</li>
	    </ul>
	    <p>1、共享</p>
	    <p>智赋云不会与其他组织、公司和个人共享您的个人信息，但以下情况除外：</p>
	    <ul>
	        <li>在获取明确同意情形下的共享: 未经您的同意，智赋云不会共享您的个人信息，除非共享的个人信息已去标识化处理，且被共享的第三方无法重新识别此类信息所属的自然人主体。智赋云仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息。</li>
	    </ul>
	    <ul>
	        <li>法定情形下的共享:智赋云可能会根据法律法规规定、诉讼争议解決需要或者行政、司法等有权机关依法提出的要求对外共享您的个人信息。</li>
	    </ul>
	    <ul>
	        <li>与关联公司共享:您的个人信息可能会与智赋云的关联公司共享。智赋云只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</li>
	    </ul>
	    <ul>
	        <li>与企业客户共享:为给您筛选适当的生产经营活动及了解您的生产经营情况，您授权并同意将您的个人信息共享给相应的企业客户。</li>
	    </ul>
	    <ul>
	        <li>与授权合作伙伴共享: 仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与授权合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。</li>
	    </ul>
	    <p>目前智赋云的授权合作伙伴主要是供应商及服务提供商。为准确地向您付款，我们需要核查您的身份信息，您同意并授权智赋云或其关联公司将您的姓名、身份证号或其他有效证件号码、手机号码、银行账号、身份证照片、人脸照片、动态面部识别特征通过应用中嵌入的软件开发工具包SDK或者APl接口共享给专业身份验证机构进行核验; 为确保您在智赋云平台线上签约的效力，您同意并授权智赋云或其关联公司将您的姓名、身份证号码或其他有效证件号码共享给认证机构用于生成您的数字证书，您同意智赋云及其关联公司调用您的数字证书。</p>
	    <p>对智赋云与之共享个人信息的公司、组织和个人，智赋云会与其签暑严格的保密协定或保密条款，要求他们按照智赋云的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
	    <p>2、转让</p>
	    <p>智赋云不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
	    <ul>
	        <li>在获取明确同意的情况下转让: 获得您的明确同意后，智赋云会向其他方转让您的个人信息;</li>
	    </ul>
	    <ul>
	        <li>在涉及合并、收购或破产清算时，如涉及到个人信息转让，智赋云会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则智赋云将要求该公司、组织重新向您征求授权同意。</li>
	    </ul>
	    <p>3、公开披露</p>
	    <p>智赋云仅会在以下情况下，公开披露您的个人信息</p>
	    <p>（1）获得您明确同意或基于您的主动选择，智赋云可能会公开披露您的个人信息;</p>
	    <p>（2）如果您出现违反法律法规或严重违反本隐私政策的情况，或为保护用户或公众的人身财产安全免遭侵害，智赋云可能依据法律法规或本隐私政策披露您的相关个人信息，包</p>
	    <p>括相关违规行为以及智赋云已对您采取的措施。</p>
	    <p>4、不得披露</p>
	    <p>(1)任何情况下，智赋云不会公开披露您的个人生物识别信息等个人生理信息;</p>
	    <p>(2)任何情况下，智赋云不会公开披露您的种族、民族、政治观点、宗教信仰等敏感数据。</p>
	    <p>5、共享、公开披露个人信息时事先征得授权同意的例外</p>
	    <p>以下情形中，共享、公开披露您的个人信息无需事先征得您的授权同意</p>
	    <p>(1)与国家安全、国防安全有关的</p>
	    <p>(2)与公共安全、公共卫生、重大公共利益有关的;</p>
	    <p>(3)与犯罪侦查、起诉、审判和判決执行等有关的;</p>
	    <p>(4)出于维护您或其他个人的生命、财产等重大合法权益但因主客观原因很难征得您本人同意的</p>
	    <p>(5)您自行向社会公众公开的个人信息;</p>
	    <p>(6)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
	    <ul>
	        <li>智赋云如何存储您的个人信息</li>
	    </ul>
	    <p>1、存储地点</p>
	    <p>智赋云依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，智赋云不会将上述信息传输至境外，如果向境外传输，智赋云将会遵循相关国家规定或者征求您的同意。</p>
	    <p>2、存储期限</p>
	    <p>智赋云仅在为提供服务之目的所必需的期间内保留您的个人信息，在您未撤回、删除或未注销账号期间，智赋云会保留相关信息。超出必要期限后，智赋云将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</p>
	    <p>五、智赋云如何保护您的个人信息</p>
	    <p>1、智赋云已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。智赋云会采取一切合理可行的措施，保护您的个人信息。</p>
	    <p>2、智赋云已取得了以下认证: PC DSS认证、ISO27001认证及信息安全等级保护三级认证。</p>
	    <p>3、智赋云仅在本隐私政策所述目的范围内和法律法规要求的时限内保留您的个人信息。智赋云使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，智赋云将利用加密技术来保护您向智赋云提供的个人信息。</p>
	    <p>4、互联网并非绝对安全的环境，而且电子邮件、即时通讯及其他交流方式并未加密，智赋云强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助智赋云保证您的账号安全。请您谅解，由于技术的限制以及风险防范的局限性，即便智赋云已经尽量加强安全防护措施，也无法始终保证信息绝对的安全。您需要了解，您接入智赋云的服务所用的系统和通讯网络，有可能因智赋云可控范围外的情况而发生问题。</p>
	    <p>5、如不幸发生个人信息安全事件，智赋云将按照法律法规的要求，及时向您告知:安全事件的基本情况和可能的影响、智赋云已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。智赋云将及时将事件相关情况以邮件、信函、电话、在线客服、推送通知等方式告知您，难以逐一告知个人信息主体时，智赋云会采取合理、有效的方式发布公告</p>
	    <p>六、您的权利</p>
	    <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，智赋云保障您对自己的个人信息行使以下权利：</p>
	    <p>1、访问您的个人信息</p>
	    <p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问: 如果您希望访问或编辑您的账户中的个人基本信息，您可以通过访问个人信息中的基本资料执行此类操作。对于您在使用智赋云产品或服务过程中产生的其他个人信息，只要智赋云不需要过多投入，我们会向您提供。</p>
	    <p>如果您无法通过上述操作访问个人信息或希望行使数据访问权，您可以随时通过第十二条中罗列的方式联系智赋云，智赋云会在30日内回复您的访问请求。</p>
	    <p>2、更正您的个人信息</p>
	    <p>当您发现智赋云处理的关于您的个人信息有错误时，您有权要求智赋云做出更正。您可以通过第十二条中罗列的方式提出更正申请。</p>
	    <p>3、删除您的个人信息</p>
	    <p>在以下情形中，您可以向智赋云提出删除个人信息的请求</p>
	    <p>(1)智赋云处理个人信息的行为违反法律法规</p>
	    <p>(2)智赋云收集、使用您的个人信息，却未征得您的同意</p>
	    <p>(3)智赋云处理个人信息的行为违反了与您的约定;</p>
	    <p>(4)您不再使用智赋云的产品或服务，或您注销了账号</p>
	    <p>(5)智赋云不再为您提供产品或服务。若智赋云決定响应您的删除请求，智赋云还将同时通知从我们处获得您的个人信息的实体，要求其及时删除(法律法规另有规定，或这些实体获得您独立授权的除外)</p>
	    <p>当您从智赋云的服务中删除信息后，智赋云可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息</p>
	    <p>4、改变您授权同意的范围</p>
	    <p>每个业务功能需要一些基本的个人信息才能得以完成(见本政策&ldquo;第一条＂)。对于额外收集的个人信息，您可以随时给予或收回您的授权同意。收回授权同意可能影响您使用某些产品或服务，智赋云会在您收回授权同意时向您说明。您可以通过第十二条中罗列的方式提出收回同意申请。当您收回同意后，智赋云将不再处理相应的个人信息。但您收回同意的決定，不会影响此前基于您的授权而开展的个人信息处理</p>
	    <p>5、响应您的上述请求</p>
	    <p>为保障安全，您可能需要提供书面请求或以其他方式证明您的身份。智赋云将先要求您验证自己的身份后再处理您的请求。</p>
	    <p>对于您的合理请求，智赋云原则上不收取费用，但对多次重复、超出合理限度的请求，智赋云将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际的请求，智赋云可能会予以拒绝。在以下情形中，智赋云将无法响应您的请求</p>
	    <p>（1）与智赋云履行法律法规规定的义务相关的;</p>
	    <p>（2）与国家安全、国防安全直接相关的;</p>
	    <p>（3）与公共安全、公共卫生、重大公共利益直接相关的;</p>
	    <p>（4）与犯罪侦查、起诉、审判和执行判決等直接相关的;</p>
	    <p>（5）智赋云有充分证据表明个人信息主体存在主观恶意或滥用权利的;</p>
	    <p>（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;</p>
	    <p>（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的;</p>
	    <p>（8）涉及商业秘密的。</p>
	    <p>七、未成年人的信息保护</p>
	    <p>智赋云非常重视对未成年人个人信息的保护。根据《智赋云平台注册协议》的约定，智赋云平台仅限已满16周岁的个人注册使用。如您已满16周岁但未满18周岁，在使用智赋云的服务前，应事先取得您法定监护人的书面同意。智赋云根据国家相关法律法规的规定保护未成年人的个人信息。</p>
	    <p>八、智赋云向您发送的信息</p>
	    <p>1、信息推送</p>
	    <p>您在使用智赋云的服务时，智赋云可能向您发送电子邮件短信、资讯或推送通知等。</p>
	    <p>2、与服务有关的公告</p>
	    <p>智赋云可能在必要时(例如，因系统维护而暂停某一项服务时)向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于广告的公告。</p>
	    <p>九、智赋云服务中的第三方服务</p>
	    <p>智赋云的服务可能包括链接至第三方提供的其他服务。该等第三方服务可能由相关的第三方或智赋云运营。您使用该等第三方的服务(包括您向该等第三方提供的任何个人信息)，须受第三方自己的服务条款及隐私政策约束，您需要仔细阅读其条款。本隐私政策仅适用于智赋云所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而智赋云对任何第三方使用由您提供的信息不承担任何责任。</p>
	    <p>十、适用范围</p>
	    <p>智赋云所有的服务均适用本隐私政策。请您注意，本隐私政策不适用于以下情况:通过智赋云的服务而接入的第三方服务收集的信息;通过在智赋云服务中提供其他服务的第三方所收集的信息。</p>
	    <p>十ー、本隐私政策的修改</p>
	    <p>智赋云可能随时修改本隐私政策的条款，该等修改构成本隐私政策的一部分。如该等修改造成您在本隐私政策下权利的实质减少，智赋云将在修改生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您，在该种情况下，若您通过点击或勾选等方式同意或继续使用智赋云的服务，即表示您同意受经修订的本隐私政策约束。</p>
	    <p>十二、如何联系我们</p>
	    <p>如果您对本隐私政策有任何疑问、意见或建议，您可以通过以下方式与我们联系：</p>
	    <p>谢谢！</p>
	
	</div>`,
    };
  },
  mounted () {
      let enterpriseLogoInfo = window.localStorage.getItem('enterpriseLogoInfo');
      if(enterpriseLogoInfo){
          this.content = JSON.parse(enterpriseLogoInfo).contract;
      }
  },
};
</script>

<style>
</style>